const styles = (theme) => {
  const { palette, baseUnit, mixins } = theme;

  return {
    root: {
      marginBottom: baseUnit * 5,
    },
    input: {
      marginBottom: baseUnit,
    },
    strengthMeter: {
      ...mixins.normalizeList,
      margin: `0 0 ${baseUnit}px 0 `,
      display: 'flex',
    },
    strengthLevel: {
      background: palette.background.secondary,
      borderRadius: 4,
      flex: 1,
      height: 3,
      marginLeft: baseUnit,
      '&:first-of-type': {
        marginLeft: 0,
      },
    },
    instructions: {
      color: palette.text.secondary,
    },
    strengthMessages: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    high: {
      background: palette.alert.high,
    },
    med: {
      background: palette.alert.med,
    },
    low: {
      background: palette.alert.low,
    },
    great: {
      background: palette.primary.main,
    },
    error: {
      paddingTop: 0,
    },
  };
};

export default styles;
