import { useState } from 'react';

import { Visibility, VisibilityOff } from 'components/ui/Atoms/Icons';
import TextInput from 'components/Forms/Inputs/TextInput';

const PasswordInput = (props) => {
  const {
    id = 'password',
    name = 'password',
    placeholder = 'Password',
    autoComplete = 'current-password',
    ...otherProps
  } = props;
  const [showPassword, setPassword] = useState(false);
  const togglePassword = () => setPassword(!showPassword);

  return (
    <TextInput
      type={showPassword ? 'text' : 'password'}
      inputSuffix={[
        {
          Icon: showPassword ? Visibility : VisibilityOff,
          onClick: togglePassword,
          label: 'Toggle password visibility',
        },
      ]}
      id={id}
      name={name}
      placeholder={placeholder}
      autoComplete={autoComplete}
      {...otherProps}
    />
  );
};

export default PasswordInput;
