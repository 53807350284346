import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { MIN_PASSWORD_LENGTH, passwordStrengthMap, getPasswordStrength } from 'helpers/forms';

import Typography from 'components/ui/Atoms/Typography';
import InputError from 'components/Forms/Inputs/InputError';
import PasswordInput from '../PasswordInput';
import styles from './PasswordInputWithStrengthStyles';

const strengthLevels = Object.keys(passwordStrengthMap);

const useStyles = makeStyles({ name: 'PasswordInputWithStrength' })(styles);

const PasswordInputWithStrength = (props) => {
  // Props
  const {
    className,
    error = false,
    errorMessage,
    instructions = `Minimum ${MIN_PASSWORD_LENGTH} characters`,
    onChange,
    id,
    name,
    ...otherProps
  } = props;

  // Hooks
  const { classes, cx } = useStyles();
  const [strength, setStrength] = useState({ level: 0, strengthClass: null, message: null });

  // Vars
  const { level, message, strengthClass } = strength;

  // Func
  const calculateStrength = (event) => {
    const password = event.target.value;
    const strength = getPasswordStrength(password);

    event.persist();
    if (onChange) onChange(event);
    setStrength(strength);
  };

  return (
    <div className={cx(classes.root, className)}>
      <PasswordInput
        className={classes.input}
        onChange={calculateStrength}
        error={error}
        id={id}
        name={name}
        {...otherProps}
      />
      <ul className={classes.strengthMeter} aria-hidden>
        {strengthLevels.map(itemLevel => (
          <li
            key={`strength-level-${itemLevel}`}
            className={cx(
              classes.strengthLevel,
              { [classes[strengthClass]]: level > 0 && itemLevel <= level },
            )}
          />
        ))}
      </ul>
      <div className={classes.strengthMessages}>
        <Typography
          variant="caption1"
          className={classes.instructions}
        >
          {message || instructions}
        </Typography>
        <InputError
          error={error}
          errorMessage={errorMessage}
          inputId={id || name}
          className={classes.error}
        />
      </div>
    </div>
  );
};

PasswordInputWithStrength.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  instructions: PropTypes.string,
  onChange: PropTypes.func,
};

export default PasswordInputWithStrength;
